import React, { useEffect, useRef, useState } from "react";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { PiNote } from "react-icons/pi";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { LuCreditCard } from "react-icons/lu";
import { PiCrownBold } from "react-icons/pi";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import DataService from "./services/requestApi";
import Swal from "sweetalert2";
import { Box, Modal } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { BASEURL } from "./services/http-common";
import {
  ApplyDiscountOnTotal,
  HandelLinkCustomer,
  RemoveCart,
  RemoveInovice,
  RemoveLoyltiyuser,
} from "./Redux/Reducer";
import CustomNavbar from "./UiComponents/CustomNavbar";
import { ClearDisplay, CreateCoupon, sendCommand } from "./Constant/Util";
import { useReactToPrint } from "react-to-print";
import {  Dropdown, DropdownButton, ModalFooter } from "react-bootstrap";
import ReceiptModal from "./UiComponents/ReaciptModal";
import moment from "moment";
import AddRemarkModal from "./UiComponents/AddRemarkModal";
import CircularProgress from '@mui/material/CircularProgress';
export const Sixth = () => {
  const dataFormat = moment(Date.now()).format('YYYY-MM-DD')
   const [Remark1, setRemark1] = useState("")
   const [Remark2, setRemark2] = useState("")
   const [Remark3, setRemark3] = useState("")
   const [Remark, setRemark] = useState("")
  const [UsdAmount, setUseAmount] = useState({
    business_date: dataFormat,
    foreign_currency: "USD",
    value: "",
  });
  const handelchange = (e) => {
    setUseAmount({
      ...UsdAmount,
      [e.target.name]: e.target.value,
    });
  };
  const [GVAmount, setGVAmount]=useState()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const focusClose = useRef();
  const [remarkopen, setRemarkopen]= useState('')
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // Add any logic you need before printing
      console.log("printing Start");
    },
    onAfterPrint: () => {
      // Add any logic you need after printing
      console.log("printing End");
      focusClose.current.focus();
    },
  });
  const displayData = useSelector((state) => state.Reducer.displayCart);
  const StartTime = useSelector((state) => state.Reducer.StartTime);
  const Salesmandata = useSelector((state) => state.Reducer.Salesmandata);
  const CouponCode = useSelector((state) => state.Reducer.CouponCode);
  // console.log("Coupon code",CouponCode)
  const TotalDiscountonInvoice = useSelector(
    (state) => state.Reducer.DicountOnTotal
  );
  const cardVal = useSelector((state) => state.Reducer.values);
  const {getPeromotionRule} = useSelector((state) => state.Reducer);
  const customer = useSelector((state) => state.Reducer.LinkCustomer);
  const [CreditId, setCreditId] = useState("");
  const [CreditDue, setCreditDue] = useState("");
  const [gv_type,setGvtype] = useState("")
  const [gv_No,setGv_no] = useState("")
  const totalamount = displayData.reduce((sum, item) => sum + item.newPrice, 0);
  const { saasId, storeId, registerId, userId, tillId ,userName} = JSON.parse(
    localStorage.getItem("User_data")
  );
  const business_date = JSON.parse(
    localStorage.getItem("business_date")
  );
  const [newCredit, setNewCredit] = useState();
  const [pdfFile, SetPdfFile] = useState("");
  const [TotalAmount, setTotalAomunt] = useState("");
  const [showPdf, SetShowPdf] = useState(false);
  const [optionTick, setOptionTick] = useState([]);
  const [selectedPort, setSelectedPort] = useState(null);
  const [newCoupon, setNewCoupon] = useState("");
  // const DiscountedTotal =(totalamount * TotalDiscountonInvoice)/100;
  const totalamountselected = optionTick?.reduce(
    (acc, item) => acc + (parseFloat(item.amount) || 0),
    0
  );
  console.log("this option", optionTick);
  const Diubalance = totalamount - totalamountselected - TotalDiscountonInvoice;
  console.log("naya totalamount Clg", totalamount);
  console.log("naya totalamountselected Clg", totalamountselected);
  const [barcodeData, setBarcodeData] = useState("");
  const [amount, setAmount] = useState(Diubalance);
  const [CutomerAmount, setCustomerAmount] = useState("");
  const [ChangeDue, setChangeDue] = useState();
  const [Tender, setTender] = useState();
  const { DisplaPort } = useSelector((state) => state.Reducer);
  // // cash Drawer Opning ----------------------------->
  // const openCashDrawer = () => {
  //   const command = '\x1B\x70\x00\x19\xFA';

  //   try {
  //     console.log('Creating iframe');
  //     const iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     document.body.appendChild(iframe);

  //     console.log('Writing command to iframe');
  //     iframe.contentDocument.write(`<pre>${command}</pre>`);

  //     setTimeout(() => {
  //       console.log('Removing iframe');
  //       document.body.removeChild(iframe);
  //     }, 1000);
  //   } catch (error) {
  //     console.error('Error opening cash drawer:', error);
  //   }
  // };

  const TenderArray = [
    {
      name: "Credit Card",
      value: "card",
      icon: (
        <LuCreditCard
          style={{ height: "40px", width: "40px", color: "blue" }}
        />
      ),
      isActive: true,
    },
    {
      name: "Cr Notes",
      value: "credit_sale",
      icon: (
        <PiNote style={{ height: "40px", width: "40px", color: "green" }} />
      ),
      isActive: true,
    },
    {
      name: "IQD",
      value: "cash",
      icon: (
        <AiOutlineDollarCircle
          style={{ height: "40px", width: "40px", color: "green" }}
        />
      ),
      isActive: true,
    },
    {
      name: "Gift Voucher",
      value: "gift Voucher",
      icon: (
        <MdOutlineCardGiftcard
          style={{ height: "40px", width: "40px", color: "maroon" }}
        />
      ),
      isActive: true,
    },
    {
      name: "USD",
      value: "USD",
      icon: (
        <LiaMoneyCheckAltSolid
          style={{ height: "40px", width: "40px", color: "black" }}
        />
      ),
      isActive: true,
    },
    {
      name: "Loyalty Points",
      value: "loyalty",
      icon: (
        <PiCrownBold
          style={{ height: "40px", width: "40px", color: "maroon" }}
        />
      ),
      isActive: cardVal[0]?.converted_cash > 0,
    },
  ];

  //Tender Amount Handel
  const handleTenderAmount = () => {
    console.log("this is option", optionTick);
    if (optionTick?.length > 0) {
      const obj = {};
      optionTick.map((item) => {
        obj[item.name] = item.amount;
      });
      return obj;
      // setSendValues(obj)
    }
    return {};
  };

  const tenderforissue =()=>{
    if(optionTick.length>0){
      const transformedArray = optionTick.map(item => ({
        tender_name: item.name,
        tender_value: item.amount
      }));
      return transformedArray;
    }
    return {};
  }
  
   
  useEffect(() => {
    if (displayData?.length == 0) {
      navigate("/");
    }
  }, [displayData]);
  const focus = useRef();
  const CreditFocucs = useRef(null);
  const UseRef = useRef(null);
  const GVFocucs = useRef(null);
  useEffect(() => {
    // Focus on the input field when the component mounts
    focus.current.focus();
  }, []);

  const HandleForiegnCurr = async (e) => {
    e.preventDefault();
    try {
      if (UsdAmount?.value) {
        const response = await DataService.ForiegnCurrncy(UsdAmount);
        console.log(response.data.data);
        if (response.data.status) {
          let CreditAmount = response.data.data.exchange_rate_value;
          // console.log(response.data.data.credit_note_amount);
          // let CreditAmount = response.data.data.credit_note_amount;
          if (CreditAmount == null) {
            Swal.fire({
              title:"Please Add Today Exchange Rate",
              icon:"error",
              timer:2000
            })
            return
          }
          console.log(optionTick)
          if(optionTick.filter((el)=>el.value=="cash").length === 0){
            console.log(optionTick)
            if (CreditAmount > Diubalance) {
              // setCreditDue(Diubalance);
              setChangeDue(CreditAmount - Diubalance);
              CreditAmount = Diubalance;
              SelecteTender(TenderArray[4], CreditAmount);
            } else {
              // setCreditDue(CreditAmount);
              console.log("aya isme")
              setChangeDue("")
              SelecteTender(TenderArray[4], CreditAmount);
            }
          }
        }
      }
    } catch (error) {
      console.log("Catch Error", error);
    }
  };
   
  const [IsSumeeting, setIsSumeeting] = useState(false)

  useEffect(() => {
    const handleKeyPress = (e) => {
      if(remarkopen){
        return
      }
      if(IsSumeeting){
        return
      }
      if(e.key === "R" || e.key === "r" && e.shiftKey){
        setTimeout(() => {
          setRemarkopen(true)
        }, 500);
      }
      if (e.key === "B" || e.key === "b" && e.shiftKey) {
        // B for pay
        e.preventDefault();
        console.log("this B");
        navigate(-1);
      }
      if (e.keyCode == 78 && e.shiftKey) {
        // N for 
        if(showPdf){
          return
        }
        e.preventDefault();
        if (Diubalance == 0) {
          console.log("this is N Button", e.keyCode);
          HandelSaveTranction();
        }
      }
      if (e.keyCode === 67 && e.shiftKey) {
        // 80 is the key code for P print
        console.log("this is C Button", e.keyCode);
        e.preventDefault();
        if (showPdf) {
          SetShowPdf(false);
          dispatch(RemoveCart());
          dispatch(HandelLinkCustomer([]));
          dispatch(RemoveInovice());
          navigate("/");
        }
      }
      if (e.keyCode === 80 && e.shiftKey) {
        // 80 is the key code for P print
        console.log("this is P Button", e.keyCode);
        e.preventDefault();
        handlePrint();
        // print()
      }
      if (e.keyCode === 112) {
        // 112 is the key code for F1
        e.preventDefault();
        console.log("this calling", amount);
        SelecteTender(TenderArray[0], amount);
      }
      if (e.keyCode === 113) {
        // 113 is the key code for F2
        // console.log("this is f2 Button",e.keyCode)
        e.preventDefault();

        if (CreditId) {
          GetCreditNote(e);
        } else {
          CreditFocucs.current.focus();
        }
        // SelecteTender(TenderArray[1],amount)
      }
      if (e.keyCode === 114) {
        // 114 is the key code for F3
        console.log("this is P Button", e.keyCode);
        e.preventDefault();
        // AmountFocucs.current.focus()
        focus.current.focus();
        SelecteTender(TenderArray[2], amount);
      }
      if (e.keyCode === 116) {
        // 114 is the key code for F3
        console.log("this is P Button", e.keyCode);
        e.preventDefault();
        // AmountFocucs.current.focus()
        if (UsdAmount?.value) {
          HandleForiegnCurr(e);
        } else {
          UseRef.current.focus();
        }
        // SelecteTender(TenderArray[2],amount)
      }
      if (e.keyCode === 115) {
        // 114 is the key code for F3
        console.log("this is P Button", e.keyCode);
        e.preventDefault();
        // AmountFocucs.current.focus()
        if(GVAmount){
          if(GVAmount>Diubalance){
            const NewGvAmount = Diubalance
            SelecteTender(TenderArray[3], NewGvAmount);
          }else{
            SelecteTender(TenderArray[3], GVAmount);

          }
        }else{
          GVFocucs.current.focus()
        }
        // SelecteTender(TenderArray[2],amount)
      }
      if (e.keyCode === 117) {
        // 116 is the key code for F5
        // console.log("this is P Button",e.keyCode)
        e.preventDefault();
        if (cardVal?.length > 0) {
          if(cardVal[0]?.converted_cash > Diubalance){
            SelecteTender(TenderArray[5], Diubalance);
          }else{
            
            SelecteTender(TenderArray[5], cardVal[0]?.converted_cash);
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [amount, showPdf, CutomerAmount, CreditId, UsdAmount,GVAmount,remarkopen,IsSumeeting]);
  useEffect(() => {
    console.log("option changes", optionTick);
    setAmount(Math.ceil(Diubalance));
    console.log("use Effect me", amount);
    console.log("use Diubalance me", Diubalance);
  }, [optionTick, Diubalance]);

  const ClearallCartData =async()=>{
    try {
      const response = DataService.DeleteAllBogo(userName)
    } catch (error) {
      console.log(error)
    }
   }
  //Handle Save Transaction
  const HandelSaveTranction = async () => {
    // console.log("This Tender",handleTenderAmount())
    setIsSumeeting(true)
    try {
      console.log("this loyality user", cardVal[0]);
      const data = {
        registerId: registerId,
        discountAmount: TotalDiscountonInvoice ?TotalDiscountonInvoice: 0,
        tillId: tillId,
        userId: userId,
        storeId: storeId,
        userName:userName,
        saasId: saasId,
        gv_no:gv_No,
        type: "Sale",
        gv_type:gv_type,
        remark1:Remark1,
        remark2:Remark2,
        remark3:Remark3,
        remarks:Remark,
        customerId:customer?.customer_id,
        salesManId: Salesmandata?.sales_man_id,
        tenderId: "TENDER1",
        tender: handleTenderAmount(),
        cartItems: displayData,
        customerName: customer?.name,
        customerNumber: customer?.mobile_number,
        loyalty_id: cardVal[0]?.loyalty_id,
        changeDue: ChangeDue,
        customer_iqd: CutomerAmount,
        customer_usd: UsdAmount.value,
        start_transaction_date_time:moment(StartTime).format('YYYY-MM-DDTHH:mm:ss')
      };
      if (optionTick.length > 0) {
        const response = await DataService.saveTransction(data);
        console.log("this response", response);
        if (response.data.status) {
          // ClearDisplay(selectedPort);
          
          if (optionTick.filter((el) => el.value == "credit_sale").length > 0) {
            if (CreditId && response.data.data.transaction_id) {
              const InvociNo = response.data.data.transaction_id
              const creditResonse = await DataService.UpdateCreditNote(
                CreditId,
                CreditDue,
                InvociNo
              );
              console.log(
                "this credit selected in this tranction",
                creditResonse.data.data
              );
              setNewCredit(creditResonse.data.data);
            }
          }
          if (saasId && cardVal[0]?.loyalty_id) {
            HandleLoyaltyissues(saasId, cardVal[0].loyalty_id,response.data.data.transaction_id);
            if(CouponCode){
              updateCoupnetoRedeemd()
            }
            if(getPeromotionRule && getPeromotionRule?.status == "Active"){
             const newCoupon = await CreateCoupon(totalamount,cardVal[0]?.loyalty_id)
             setNewCoupon(newCoupon)
            }
          }
          
          
          SetPdfFile(response.data.data.pdf_file_name);
          setTotalAomunt(response.data.data.total_amount);
          setBarcodeData(response.data.data.transaction_id);
          setTender(response.data.data.tender);
          console.log(response.data.data.total_amount)
          SetShowPdf(true);
          dispatch(RemoveLoyltiyuser());
          dispatch(ApplyDiscountOnTotal(0));
          ClearallCartData()
          setIsSumeeting(false)
        } else{
          setIsSumeeting(false)
          Swal.fire({
            title: response.data.message,
            icon: "error",
          });
        }
        setTimeout(() => {
          sendCommand(`Thanks For Shoping with us`, selectedPort);
        }, 2000);
      } else {
        setIsSumeeting(false)
        Swal.fire({
          title: "Please Selecte Tender",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title:error.response.data.message,
        text:"This Transcation not completed",
        icon:"error",
      })
      console.log("Save Transaction", error);
      navigate('/')
      dispatch(RemoveInovice());
      setIsSumeeting(false)
      dispatch(RemoveCart());
    }
  };
  
  const LoyaltiyPayload=()=>{
    const Itemdata = displayData
    const EditedItem = Itemdata.map((item)=>
    item.discount >0 ?{
     ...item,
     "product_name": item.name,
      "product_quantity": item.productQty ,
      "product_amount": item.actual_price,
      "product_non_sale_amount": "",
      "product_sale_amount": item.newPrice,
      "product_discount_amount": item.discount,
     qr_sale_flag:true,
    }:{
      ...item,
      "product_name": item.name,
      "product_quantity": item.productQty ,
      "product_amount": item.actual_price,
      "product_non_sale_amount": item.newPrice,
      "product_sale_amount": "",
      "product_discount_amount": item.discount,
      qr_sale_flag:false,
    }
    )
    return EditedItem
  }

  //Loyality Issue Api Initigrate
  const HandleLoyaltyissues = async (saasId, LoyaltyId,invoice_no) => {
    try {
       const EditedData = LoyaltiyPayload()
       console.log(EditedData)
      const data = {
        customer_id: cardVal[0]?.customer_id,
        source_channel: "Online",
        cost_centre: "Marketing",
        register_id: "R789",
        total_invoice_amount: totalamount,
        store_id: storeId,
        business_date: business_date,
        invoice_no: invoice_no,
        source_app: "WebApp",
        concept_code: "1",
        reference_number: "REF789",
        source_function: "Sales",
        territory_code: "INR",
        transaction_type: "Purchase",
        remarks: "Special notes",
        client_id: cardVal[0].client_id,
        product: EditedData,
        redeemed_point: 100.0,
        redeemed_concept: "Discount",
        base_currency: "INR",
        status: "Completed",
        country: "INDIA",
        tender: tenderforissue(),
      };
      const response = await DataService.LoyalityIssues(
        saasId,
        LoyaltyId,
        data
      );
      if(response.data.status){
        if(optionTick.filter((el) => el.value == "loyalty").length > 0){
          const redeemamount= optionTick.filter((el) => el.value == "loyalty")
          console.log("redeem amount loyality",redeemamount[0].amount
         )
           const LolyalityuData = 
             {
               "redeem_amount": redeemamount[0]?.amount
               ,
               "bussiness_date": business_date,
               "invoice_number": invoice_no,
               "remarks": ""
           }
          await DataService.RedeemLoyalti(LoyaltyId,LolyalityuData)
       }
      }
    } catch (error) {
      console.log("this loyalti issues error", error);
    }
  };

  const style = {
    overflowY: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: 635,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // p: 4,
  };

  //Calcualte Discounte On Invoice Level

  //Function For key Press Transaction
  const SelecteTender = (item, amount) => {
    console.log(item,optionTick);
    if(optionTick.filter((el)=>el.value == "cash").length>0){
      console.log("aya cash",optionTick)
      return
    }
    if (optionTick?.length === 0) {
      // console.log("this amount", CutomerAmount);
      if (item.value == "cash") {
        if (CutomerAmount < Diubalance) {
          focus.current.focus();
          // Swal.fire({
          //   title:"Please Enter Valid Amount",
          //   icon:"error",
          //   timer:2000
          // })
        } else {
          setChangeDue(CutomerAmount - Diubalance);
          // setCustomerAmount("");
          const obj = { ...item, amount };
          setOptionTick([...optionTick, obj]);
        }
      } else {
        const obj = { ...item, amount };
        setOptionTick([...optionTick, obj]);
      }
      // setAmount(Diubalance)
      console.log(optionTick.length);
    } else if (optionTick?.length > 0) {
      console.log(optionTick.length);
      if (optionTick.filter((io) => io.value === item.value)?.length > 0) {
        console.log("unselecte", optionTick);
        //unSelecte tender
        if (item.value == "cash" ||item.value == "USD") {
          setChangeDue("");
          setOptionTick(optionTick.filter((io) => io.value !== item.value));
        }

        setOptionTick(optionTick.filter((io) => io.value !== item.value));
        // setAmount(Diubalance)
      } else {
        if (Diubalance <= 0) {
          Swal.fire({
            text: "No Amount Due",
            icon: "success",
            timer: 1000,
          });
        } else {
          console.log("yhaan aya ye", item.value);
          if (item.value == "cash") {
            if (CutomerAmount < Diubalance) {
              focus.current.focus();
              // Swal.fire({
              //   title:"Please Enter Valid Amount",
              //   icon:"error",
              //   timer:2000
              // })
            } else {
              setChangeDue(CutomerAmount - Diubalance);
              // setCustomerAmount("");
              const obj = { ...item, amount };
              setOptionTick([...optionTick, obj]);
            }
          } else {
            const obj = { ...item, amount };
            setOptionTick([...optionTick, obj]);
          }
        }
        // setAmount(Diubalance)
      }
    }
  };
  // <------------------------------------------------------------------------------------------------------------->
  useEffect(() => {
    if (DisplaPort.length > 0 && !selectedPort) {
      // For simplicity, selecting the first available port
      const firstPort = DisplaPort[0];
      console.log("Using port:", firstPort);
      setSelectedPort(firstPort);

      // Open the selected port
      openSelectedPort(firstPort);
    }
  }, [DisplaPort]);

  const openSelectedPort = async (port) => {
    try {
      await port.open({ baudRate: 9600 });
      console.log("Serial port opened successfully.");
    } catch (error) {
      console.error("Error opening serial port:", error.message);
    }
  };
  // const sendCommand = async (command) => {
  //   console.error(command);

  //   if (selectedPort) {
  //     try {
  //       const writer = selectedPort.writable.getWriter();
  //       const encoder = new TextEncoder();

  //       await writer.write(encoder.encode(command));
  //       console.log('Command sent successfully:', command);

  //       // Release the writer after writing
  //       writer.releaseLock();

  //     } catch (error) {
  //       console.error('Error writing to serial port:', error);
  //     }
  //   } else {
  //     console.error('Serial port is not open.');
  //   }
  // };

  useEffect(() => {
    // sendCommand("",selectedPort); // Replace 'clear' with the command to clear the screen
    ClearDisplay(selectedPort);
    setTimeout(() => {
      sendCommand(`Total Amount:${totalamount}`, selectedPort);
    }, 3000);
  }, [selectedPort]);

  const customLineStyle = {
    borderTop: "1px solid black",
    margin: "10px 0",
    width: "100%",
  };

  const header = {
    container: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
    text: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
  };
  //  console.log("this is total deu",Diubalance,totalamountselected,handleTenderAmount())

  const GetCreditNote = async (e) => {
    e.preventDefault();
    try {
      const response = await DataService.CreditNoteGet(CreditId);
      if (response.data.status) {
        console.log(response.data.data.credit_note_amount);
        let CreditAmount = response.data.data.credit_note_amount;
        if (CreditAmount > Diubalance) {
          setCreditDue(Diubalance);
          CreditAmount = Diubalance;
          SelecteTender(TenderArray[1], CreditAmount);
        } else {
          setCreditDue(CreditAmount);
          SelecteTender(TenderArray[1], CreditAmount);
        }
      } else {
        setCreditId("");
        Swal.fire({
          title: "This Credit Note Already REDEEM",
          icon: "error",
        });
      }
    } catch (error) {
      setCreditId("");
      Swal.fire({
        title: "Not Found",
        text: `With This ${CreditId} Credit Id NO Credit Note Found`,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (showPdf === true) {
      console.log("useEffact Run ");
      // e.preventDefault();
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }
  }, [showPdf]);


  const updateCoupnetoRedeemd=async()=>{
    try {
      const response  = await DataService.UpdateCoupn(CouponCode)
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <>
    {IsSumeeting ?
    <>
    <Box sx={{ display: 'flex' ,justifyContent:"center" , flexDirection:"column", alignItems:"center"}}>
      <CircularProgress style={{width:"120px", height:"120px"}}/>
    <span>Please Wait Your Transaction in Progress Do Not Go Back</span>
    </Box>
    </>
     :<>  
      <Container className="d-flex justify-content-between">
        <Link to={"/scan"} className="mt-3" style={{ color: "black" }}>
          {" "}
          <AiOutlineArrowLeft
            style={{ width: "30px", height: "20px", marginLeft: "50px" }}
          />
        </Link>
        <CustomNavbar />
        <div>{""}</div>
      </Container>
      <div style={{ height: "fit-content", width: "100%" }}>
        <hr />
        <Container>
          <Row style={{ height: "fit-content" }}>
            <Col>
            <h4
              style={{
                color: "#2C2C2C",

                fontFamily: "Inter",
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              Pay using
            </h4>
            <span className="fw-bold text-danger">
              *Select Cash As Last Tender For Split Tender*
            </span>
            </Col>
            <Col>  <Button className="w-25" onClick={()=>setRemarkopen(true)}>Add Remarks (Shift+R)</Button></Col>
            <div className="row">
              {TenderArray.filter((item) => item.isActive === true).map(
                (item, index) => {
                  return (
                    <div
                      className="mx-2 text-center"
                      onClick={(e) => {
                        if (item.name == "Cr Notes") {
                          if (CreditId) {
                            GetCreditNote(e);
                          } else {
                            CreditFocucs.current.focus();
                          }
                        } else if (item.value == "USD") {
                          if (UsdAmount) {
                            console.log("console in If", UsdAmount);
                            HandleForiegnCurr(e)
                          } else {
                            UseRef.current.focus();
                            //  console.log('console in else')
                          }
                        }else if(item.value == "gift Voucher"){
                          console.log("amount",GVAmount)
                           if(GVAmount){
                            if(GVAmount>Diubalance){
                              const NewGvAmount = Diubalance
                              SelecteTender(TenderArray[3], NewGvAmount);
                            }else{
                              SelecteTender(TenderArray[3], GVAmount);
                  
                            }
                           }
                        }else if(item.value == "loyalty"){
                          if(cardVal[0]?.converted_cash>Diubalance){
                            SelecteTender(item, Diubalance);
                          }else{
                            SelecteTender(item, cardVal[0]?.converted_cash);
                          }
                        }else{
                          SelecteTender(item, amount);
                          
                        }
                      }}
                      style={{
                        height: "210px",
                        width: "10rem",
                        border: "solid grey 1px",
                        borderRadius: "5px",
                        marginLeft: "",
                      }}
                    >
                     { item.value == "gift Voucher" &&<Form.Select ref={GVFocucs} onChange={(e)=>{setGvtype(e.target.value)}} aria-label="Default select example">
      <option>type</option>
      <option value="Mall GV">Mall GV </option>
      <option value="Geo Telecom GV">Geo Telecom GV</option>
      <option value="Vast Retl GV">Vast Retl GV</option>
    </Form.Select>}

                      {item.icon}
                      <h6 className="text-center">{item.name}</h6>
                      {item.value == "credit_sale" && (
                        <Form
                          style={{ height: "2rem" }}
                          onSubmit={GetCreditNote}
                          className="d-flex "
                        >
                          <FormControl
                            type="number"
                            placeholder="Credit Number"
                            value={CreditId}
                            onChange={(e) => setCreditId(e.target.value)}
                            className="mr-2"
                            aria-label="Search"
                            ref={CreditFocucs}
                          />
                        </Form>
                      )}
                      {item.value == "USD" && (
                        <Form
                          style={{ height: "2rem" }}
                          onSubmit={HandleForiegnCurr}
                          className="d-flex "
                        >
                          <FormControl
                            type="number"
                            placeholder="Enter USD"
                            value={UsdAmount.value}
                            name="value"
                            onChange={(e) => handelchange(e)}
                            className="mr-2"
                            aria-label="Search"
                            ref={UseRef}
                          />
                        </Form>
                      )}
                      {item.value == "gift Voucher" && (
                        <Form
                          style={{ height: "fit-content" }}
                          onSubmit={(e)=>{
                            e.preventDefault()
                            if(GVAmount){
                              SelecteTender(item,GVAmount)
                            }
                          }}
                          // className="d-flex "
                        >
                           <FormControl
                          //  ref={GVFocucs}
                            type="text"
                            placeholder="Enter Serial Number"
                            value={gv_No}
                            minLength={18}
                            // name="value"
                            onChange={(e) => {setGv_no(e.target.value)}}
                            className="mr-2"
                            aria-label="Search"
                            disabled={gv_No.length >= 18}
                            isInvalid={gv_No.length >= 18} 
                            // ref={UseRef}
                          />
                          <FormControl
                            type="number"
                            placeholder="Enter Amount"
                            value={GVAmount}
                            name="value"
                            onChange={(e) => {setGVAmount(e.target.value)}}
                            className="mr-2"
                            aria-label="Search"
                            // ref={UseRef}
                          />
                         
                        </Form>
                      )}
                      {item.value == "loyalty"&& 
                      <p>Bal: {cardVal[0]?.converted_cash}</p>
                      }
                      <span
                        style={{ border: "2px solid gray" }}
                        className="rounded px-1"
                      >
                        F{index + 1}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </Row>
          <hr />
          <Row className="justify-content-between">
            <div style={{ width: "fit-content" }}>
              <h5>Customer Cash Amount</h5>
              <FormControl
                type="number"
                ref={focus}
                disabled={Diubalance <= 0 ? true : false}
                placeholder="Enter Amount Given by Customer"
                value={CutomerAmount}
                onChange={(e) => setCustomerAmount(e.target.value)}
                className="mr-2"
              />
            </div>
            <div style={{ height: "180px", width: "440px" }}>
              <h5>Tender Amount</h5>
              {/* <h6 style={{ marginTop: "10px" }}>AED</h6> */}
              <Form style={{ width: "400px", marginLeft: "0px" }}>
                <FormControl
                  type="search"
                  disabled
                  placeholder="1500"
                  value={
                    TotalDiscountonInvoice !== 0
                      ? Math.ceil(totalamount - TotalDiscountonInvoice)
                      : Math.ceil(totalamount)
                  }
                  className="mr-2"
                  aria-label="Search"
                />
              </Form>
              <div style={{ height: "fit-content", width: "fit-content" }}>
                <h5>Split Tender</h5>
                <FormControl
                  type="text"
                  placeholder="Enter Amount For split"
                  disabled={Diubalance <= 0 ? true : false}
                  onChange={(e) => {
                    if (e.target.value == 0) setAmount(Diubalance);
                    if (e.target.value <= Diubalance) {
                      setAmount(e.target.value);
                    } else {
                      setAmount(Diubalance);
                    }
                  }}
                  value={amount}
                  className="mr-2"
                />
                <div
                  className="fw-bold d-flex justify-content-around mb-1"
                  style={{
                    background: "linear-gradient(45deg, #ffc800, transparent)",
                    borderRadius: "10px",
                  }}
                >
                  <h5>Selected Tenders</h5>
                </div>
                {optionTick.map((item) => {
                  return (
                    <div
                      className="fw-bold d-flex justify-content-between"
                      style={{
                        background:
                          "linear-gradient(45deg, #ffc800, transparent)",
                        borderRadius: "10px",
                      }}
                    >
                      <span>{item.name}</span> <span>{item.amount}</span>
                    </div>
                  );
                })}
              </div>
              <div className="d-grid">
                {ChangeDue > 0 && (
                  <span className="fw-bold fs-3">
                    Changes Due: {ChangeDue}{" "}
                  </span>
                )}
                <Button
                className="fw-bold fs-6"
                  onClick={() => {
                    if(IsSumeeting){

                    }else{
                      HandelSaveTranction();
                    }
                  }}
                  disabled={Diubalance == 0 ? false : true }
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    height: "50px",
                    fontSize: "13px",
                  }}
                  variant="primary"
                >
                  {" "}
                  PAY NOW (Shift + N)
                </Button>
              </div>
            </div>
          </Row>
        </Container>

        {/* Recipte show modal */}
        <Modal
          open={showPdf}
          // onClose={}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              // onClick={() => {
              //   SetShowPdf((state) => !state);
              //   dispatch(RemoveCart());
              //   navigate("/");
              // }}
            >
              <AiOutlineClose size={20} />
            </div>

            <div
              className="container"
              style={header.container}
              ref={componentRef}
            >
              {/* <div style={{ height: "550px", overflowY: "auto" }}> */}
              <ReceiptModal
              UsdAmount = {UsdAmount}
              CutomerAmount={CutomerAmount}
                newCredit={newCredit}
                CreditId={CreditId}
                TotalAmount={TotalAmount}
                barcodeData={barcodeData}
                ChangeDue={ChangeDue}
                Tender={Tender}
                newCoupon={newCoupon}
              />

              {/* </div> */}
            </div>

            <ModalFooter>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="bg-dark-subtle mt-10 p-2"
                  onClick={() => {
                    SetShowPdf(false);
                    dispatch(RemoveCart());
                    dispatch(HandelLinkCustomer([]));
                    dispatch(RemoveInovice());
                    navigate("/");
                  }}
                  ref={focusClose}
                >
                  Close (Shift + C )
                </button>
                <button
                  className="bg-dark-subtle mt-10 p-2"
                  onClick={handlePrint}
                >
                  PRINT (Shift + P )
                </button>

              </div>
            </ModalFooter>

            {/* {pdfFile && (
                <div style={{ height: "400px" }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={`${BASEURL.ENDPOINT_URL}/transaction/pdf/${pdfFile}`}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </div>
              )} */}
          </Box>
        </Modal>
      </div>
      <AddRemarkModal setRemark={setRemark} setRemark1={setRemark1} setRemark2={setRemark2} setRemark3={setRemark3}  show={remarkopen} onHide={() => setRemarkopen(false)} />
      </>}  
    </>
  );
};
